<template>
  <v-container
    :fluid="fluid"
    class="full-height"
    :class="showEmptyState || loading ? 'd-flex flex-column' : ''"
  >
    <div
      class="d-flex align-center justify-space-between flex-wrap background"
      style="gap: 10px; position: sticky; top: 56px; z-index: 100"
    >
      <v-breadcrumbs
        large
        :items="breadcrumbsItems"
        v-if="breadcrumbsItems.length"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="font-weight-semibold info--text"
            :to="item.to"
            :disabled="item.disabled"
            :exact="item.exact"
            @click.native="onBreadcrumbItemClicked(item)"
            style="cursor: pointer"
          >
            <v-icon v-if="item.isHome" color="info" class="ml-1" :size="20">{{
              mdiHome
            }}</v-icon>
            <span
              v-if="!item.disabled"
              class="text-decoration-underline text-title info--text"
              >{{ item.text }}</span
            >
            <span class="text-title" v-else>{{ item.text }}</span>
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon>{{ mdiChevronLeft }}</v-icon>
        </template>
      </v-breadcrumbs>
      <cz-input
        v-if="searchEnabled && !isSearchBarInToolbar && !showEmptyState"
        v-model="searchTextLocal"
        :placeholder="searchPlaceholder || $t('common.search')"
        dense
        class="search-field"
        clearable
      />
      <slot name="toolbar-actions" />
    </div>
    <slot name="header" />
    <v-toolbar
      v-if="toolbarEnabled"
      :color="toolbarColor"
      rounded="lg"
      :flat="toolbarFlat"
      class="overflow-hidden mt-2"
      :height="toolbarHeight"
      :max-height="toolbarHeight"
    >
      <slot name="toolbar" />
      <template v-if="isSearchBarInToolbar && !showEmptyState">
        <v-spacer />
        <cz-input
          v-model="searchTextLocal"
          :placeholder="searchPlaceholder || $t('common.search')"
          class="search-field"
          clearable
          hide-details
          dense
        />
      </template>
    </v-toolbar>
    <div
      v-if="showEmptyState && !loading"
      class="flex-grow-1 align-center d-flex justify-center"
    >
      <cz-empty-state :message="esMessage" />
    </div>
    <div v-else-if="loading" class="full-height d-flex align-center">
      <cz-spinner class="flex-grow-1" />
    </div>
    <div v-else>
      <slot />
    </div>
  </v-container>
</template>

<script>
import { CzEmptyState, CzSpinner, CzInput } from '@/components';
import { mdiChevronLeft, mdiHome } from '@mdi/js';
import { debounce } from 'vue-debounce';
export default {
  name: 'ContentLayout',
  components: {
    CzEmptyState,
    CzSpinner,
    CzInput
  },
  props: {
    fluid: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    showEmptyState: {
      type: Boolean,
      default: false
    },
    emptyStateMessage: {
      type: String
    },
    breadcrumbsItems: {
      type: Array,
      default: () => []
    },
    searchText: {
      type: String,
      default: ''
    },
    searchEnabled: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String
    },
    searchDebounceEnabled: {
      type: Boolean,
      default: false
    },
    toolbarEnabled: {
      type: Boolean,
      default: false
    },
    toolbarColor: {
      type: String,
      default: 'background'
    },
    searchBarInToolbar: {
      type: Boolean,
      default: false
    },
    searchDebounceMs: {
      type: Number,
      default: 600
    },
    toolbarFlat: {
      type: Boolean,
      default: true
    },
    toolbarHeight: {
      type: [String, Number],
      default: 60
    }
  },
  created() {
    this.searchTextLocal = this.searchText;
  },
  data() {
    return {
      mdiChevronLeft,
      mdiHome,
      searchTextLocal: '',
      debounce: null
    };
  },
  computed: {
    esMessage() {
      if (!this.emptyStateMessage) {
        return this.$t('common.emptyStateDefaultMessage');
      } else {
        return this.emptyStateMessage;
      }
    },
    isSearchBarInToolbar() {
      return this.toolbarEnabled && this.searchEnabled;
    }
  },
  methods: {
    onBreadcrumbItemClicked(item) {
      if (item.handler) {
        item.handler();
      }
    }
  },
  watch: {
    searchTextLocal: {
      handler(value) {
        if (this.searchDebounceEnabled) {
          // if debounce enabled we need to cancel it because it will run anytime
          if (this.debounce) {
            this.debounce.cancel();
          }
          this.debounce = debounce(
            (val) => this.$emit('update:searchText', val),
            this.searchDebounceMs
          );
          this.debounce(value);
        } else {
          this.$emit('update:searchText', value);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-field {
  max-width: 450px !important;
  width: 350px !important;
  min-width: 250px !important;
}
</style>
